import type { Svg, Box } from "@svgdotjs/svg.js";

export class Panner {
  private draw: Svg;
  private startPosition: [number, number] = null;
  private startViewbox: Box;
  private panning = false;

  constructor(draw: Svg) {
    this.draw = draw;
    this.panHandler = this.panHandler.bind(this);
  }

  get isPanning(): boolean {
    return this.panning;
  }

  startPan(mouseX: number, mouseY: number): void {
    if (this.panning) {
      return;
    }
    this.panning = true;
    this.startPosition = [mouseX, mouseY];
    this.startViewbox = this.draw.viewbox();
    window.addEventListener("touchmove", this.panHandler);
    window.addEventListener("mousemove", this.panHandler);
  }

  panHandler(event: MouseEvent | TouchEvent): void {
    const [startX, startY] = this.startPosition;

    const objectWithClientCoordinates =
      event instanceof MouseEvent ? event : event.targetTouches.item(0);
    const { clientX, clientY } = objectWithClientCoordinates;

    this.draw.viewbox(
      startX - clientX + this.startViewbox.x,
      startY - clientY + this.startViewbox.y,
      this.startViewbox.width,
      this.startViewbox.height
    );
  }

  stopPan(): void {
    window.removeEventListener("touchmove", this.panHandler);
    window.removeEventListener("mousemove", this.panHandler);
    this.panning = false;
  }
}
