import type { Svg, Path } from "@svgdotjs/svg.js";

export class Painter {
  private draw: Svg;

  private currentPath: Path;

  constructor(draw: Svg) {
    this.draw = draw;
    this.undo = this.undo.bind(this);
  }

  beginLine(
    color: string,
    x: number,
    y: number,
    relativeToViewBox = true
  ): void {
    if (relativeToViewBox) {
      [x, y] = this.makeRelativeToViewBox(x, y);
    }

    this.currentPath = this.draw
      .path(`M${x} ${y}`)
      .stroke({ color, width: 3 })
      .fill("transparent");
  }

  endLine(): void {
    this.currentPath = null;
  }

  makeRelativeToViewBox(x: number, y: number): [number, number] {
    const box = this.draw.viewbox();
    x += box.x;
    y += box.y;
    return [x, y];
  }

  lineTo(x: number, y: number, relativeToViewBox = true): void {
    if (!this.currentPath) {
      return;
    }

    if (relativeToViewBox) {
      [x, y] = this.makeRelativeToViewBox(x, y);
    }

    const currentPlot = this.currentPath.plot();
    this.currentPath.plot(currentPlot.concat([["L", x, y]]));
  }

  undo(): void {
    const children = this.draw.children();
    if (children.length > 1) {
      children[children.length - 1].remove();
    }
  }
}
