export function extractMouseEventPosition(event: MouseEvent): [number, number] {
  const { clientX, clientY } = event; // I don't use offsetX/Y here becuase they were sometimes hectic
  const { offsetLeft, offsetTop } = event.currentTarget as HTMLElement;
  const [x, y] = [clientX - offsetLeft, clientY - offsetTop];
  return [x, y];
}

export function extractTouchEventPosition(event: TouchEvent): [number, number] {
  const { clientX, clientY } = event.touches[0]; // I don't use offsetX/Y here becuase they were sometimes hectic
  const { offsetLeft, offsetTop } = event.currentTarget as HTMLElement;
  const [x, y] = [clientX - offsetLeft, clientY - offsetTop];
  return [x, y];
}
