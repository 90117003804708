import { writable } from "svelte/store";

const firstColor = "lightgrey";

export const selectedColor = writable<string>(firstColor);

export const palette = writable<string[]>([
  firstColor,
  "salmon",
  "aqua",
  "forestgreen",
  "gold",
]);
