import type { Painter } from "./painter";
import type { Panner } from "./panner";
import {
  extractMouseEventPosition,
  extractTouchEventPosition,
} from "./helpers";
import { selectedColor } from "./stores";

enum Action {
  PAN,
  DRAW,
}

function endLine(
  event: MouseEvent | TouchEvent,
  painter: Painter,
  panner: Panner
): void {
  event.preventDefault();
  painter.endLine();
  panner.stopPan();
}

let color = "";
selectedColor.subscribe((newColor) => {
  color = newColor;
});

export function addListeners(
  element: HTMLElement,
  painter: Painter,
  panner: Panner
): void {
  addTouchEventListeners(element, painter, panner);
  addMouseEventListeners(element, painter, panner);
}

function addTouchEventListeners(
  element: HTMLElement,
  painter: Painter,
  panner: Panner
) {
  element.addEventListener("touchstart", function (event) {
    event.preventDefault();
    painter.beginLine(color, ...extractTouchEventPosition(event));
  });

  element.addEventListener("touchend", function (event) {
    endLine(event, painter, panner);
  });

  element.addEventListener("touchcancel", function (event) {
    endLine(event, painter, panner);
  });

  element.addEventListener("touchmove", function (event) {
    event.preventDefault();
    const [x, y] = extractTouchEventPosition(event);
    let action: Action;

    if (event.shiftKey || event.touches.length > 1) {
      action = Action.PAN;
    } else {
      action = Action.DRAW;
    }

    switch (action) {
      case Action.DRAW:
        painter.lineTo(x, y);
        break;
      case Action.PAN:
        panner.startPan(x, y);
        break;
    }
  });
}

function addMouseEventListeners(
  element: HTMLElement,
  painter: Painter,
  panner: Panner
) {
  element.addEventListener("mousedown", function (event) {
    event.preventDefault();
    if (event.shiftKey || event.buttons === 4) {
      panner.startPan(event.clientX, event.clientY);
    } else if (event.buttons === 1) {
      painter.beginLine(color, ...extractMouseEventPosition(event));
    }
  });

  window.addEventListener("mouseup", function (event) {
    endLine(event, painter, panner);
  });

  element.addEventListener("mousemove", function (event) {
    if (event.buttons === 0) {
      return;
    }

    event.preventDefault();
    const [x, y] = extractMouseEventPosition(event);
    let action: Action;

    if (event.shiftKey || event.buttons === 4) {
      action = Action.PAN;
    } else {
      if (event.buttons === 1) {
        action = Action.DRAW;
      }
    }

    switch (action) {
      case Action.DRAW:
        painter.lineTo(x, y);
        break;
      case Action.PAN:
        panner.startPan(x, y);
        break;
    }
  });
}
