import "@babel/polyfill";
import { Svg, SVG } from "@svgdotjs/svg.js";
import download from "downloadjs";

import { Painter } from "./painter";
import { Panner } from "./panner";
import { addListeners } from "./eventhandlers";

import Palette from "./components/Palette.svelte";
import Actions from "./components/Actions.svelte";

window.addEventListener("DOMContentLoaded", function () {
  const root = document.getElementById("app");
  const draw = SVG().addTo(root);
  const painter = new Painter(draw);
  const panner = new Panner(draw);

  function size() {
    const [width, height] = [window.innerWidth, window.innerHeight];
    draw.size(width, height);
    draw.viewbox(0, 0, width, height);
  }

  size();
  window.addEventListener("resize", size);

  draw.css("background", "black");

  draw
    .text(
      "Draw with left click/stylus, pan with middle button or >1 fingers, pan with shift+click/stylus"
    )
    .fill("cyan")
    .move(200, 50);

  addListeners(root, painter, panner);

  new Palette({
    target: document.getElementById("palette"),
  });

  function exportSVG(draw: Svg) {
    const prevViewbox = draw.viewbox();
    const bbox = draw.bbox();
    draw.viewbox(bbox);
    const svgContent = draw.svg();
    draw.viewbox(prevViewbox);
    download(svgContent, "awesome-sketch.svg", "image/svg+xml");
  }

  new Actions({
    target: document.getElementById("actions"),
    props: {
      onUndoClick: painter.undo,
      onExportSVG: () => exportSVG(draw),
    },
  });
});
