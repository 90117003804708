/* components/Actions.svelte generated by Svelte v3.28.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	is_function,
	listen,
	noop,
	run_all,
	safe_not_equal,
	space
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-v12ztx-style";
	style.textContent = ".root.svelte-v12ztx{--button-size:max(6vw, 5vh);position:fixed;top:0;left:0}button.svelte-v12ztx{width:var(--button-size);height:var(--button-size);background:black;color:lightgrey;border:1x solid lightgrey;border-radius:50%;border-style:solid;cursor:pointer}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;
	let button0;
	let t1;
	let button1;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			button0 = element("button");
			button0.textContent = "Undo \"Z\"";
			t1 = space();
			button1 = element("button");
			button1.textContent = "Export SVG";
			attr(button0, "type", "button");
			attr(button0, "class", "svelte-v12ztx");
			attr(button1, "type", "button");
			attr(button1, "class", "svelte-v12ztx");
			attr(div, "class", "root svelte-v12ztx");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, button0);
			append(div, t1);
			append(div, button1);

			if (!mounted) {
				dispose = [
					listen(button0, "click", function () {
						if (is_function(/*onUndoClick*/ ctx[0])) /*onUndoClick*/ ctx[0].apply(this, arguments);
					}),
					listen(button1, "click", function () {
						if (is_function(/*onExportSVG*/ ctx[1])) /*onExportSVG*/ ctx[1].apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { onUndoClick } = $$props;
	let { onExportSVG } = $$props;

	window.addEventListener("keypress", function (event) {
		if (event.key === "z") {
			event.preventDefault();
			onUndoClick();
		}
	});

	$$self.$$set = $$props => {
		if ("onUndoClick" in $$props) $$invalidate(0, onUndoClick = $$props.onUndoClick);
		if ("onExportSVG" in $$props) $$invalidate(1, onExportSVG = $$props.onExportSVG);
	};

	return [onUndoClick, onExportSVG];
}

class Actions extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-v12ztx-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { onUndoClick: 0, onExportSVG: 1 });
	}
}

export default Actions;