/* components/Palette.svelte generated by Svelte v3.28.0 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	is_function,
	listen,
	noop,
	null_to_empty,
	safe_not_equal,
	set_store_value,
	set_style
} from "svelte/internal";

import classnames from "classnames";
import { palette, selectedColor } from "../stores";

function add_css() {
	var style = element("style");
	style.id = "svelte-jpe4p2-style";
	style.textContent = ".root.svelte-jpe4p2{--border-radius:10px;--color-size:max(6vw, 6vh);position:fixed;bottom:0;left:0}.colors.svelte-jpe4p2{display:flex;flex-direction:column}.color.svelte-jpe4p2{font-size:0;display:block;width:var(--color-size);height:var(--color-size)}.color.svelte-jpe4p2:first-child{border-radius:0 var(--border-radius) 0 0}.color.selected.svelte-jpe4p2,.color.svelte-jpe4p2:hover{width:calc(var(--color-size) + 3vw);border-radius:0 var(--border-radius) var(--border-radius) 0}.color.svelte-jpe4p2:hover{width:calc(var(--color-size) + 2vw);cursor:pointer}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[3] = list[i];
	return child_ctx;
}

// (49:4) {#each $palette as color}
function create_each_block(ctx) {
	let div;
	let div_class_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");

			attr(div, "class", div_class_value = "" + (null_to_empty(classnames("color", {
				selected: /*color*/ ctx[3] === /*$selectedColor*/ ctx[0]
			})) + " svelte-jpe4p2"));

			set_style(div, "background", /*color*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = listen(div, "click", function () {
					if (is_function(/*selectColor*/ ctx[2](/*color*/ ctx[3]))) /*selectColor*/ ctx[2](/*color*/ ctx[3]).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$palette, $selectedColor*/ 3 && div_class_value !== (div_class_value = "" + (null_to_empty(classnames("color", {
				selected: /*color*/ ctx[3] === /*$selectedColor*/ ctx[0]
			})) + " svelte-jpe4p2"))) {
				attr(div, "class", div_class_value);
			}

			if (dirty & /*$palette*/ 2) {
				set_style(div, "background", /*color*/ ctx[3]);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let each_value = /*$palette*/ ctx[1];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div1 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", "colors svelte-jpe4p2");
			attr(div1, "class", "root svelte-jpe4p2");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div0, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*classnames, $palette, $selectedColor, selectColor*/ 7) {
				each_value = /*$palette*/ ctx[1];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $selectedColor;
	let $palette;
	component_subscribe($$self, selectedColor, $$value => $$invalidate(0, $selectedColor = $$value));
	component_subscribe($$self, palette, $$value => $$invalidate(1, $palette = $$value));

	function selectColor(color) {
		set_store_value(selectedColor, $selectedColor = color, $selectedColor);
	}

	return [$selectedColor, $palette, selectColor];
}

class Palette extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-jpe4p2-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Palette;